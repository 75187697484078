import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import Bootstrap JS bundle

function Research() {
  return (
    <div>
      {/* Sticky Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow">
        <div className="container">
          <a className="navbar-brand" href="#home">
            Omar Abdel Aziz
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#education">
                  Education
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#skills">
                  Skills
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#research-experience">
                  Research Experience
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#professional-experience">
                  Professional Experience
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#projects">
                  Projects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#publications">
                  Publications
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section
        id="home"
        className="d-flex align-items-center"
        style={{
          minHeight: "100vh",
          background: "linear-gradient(90deg, #007bff 0%, #6610f2 100%)",
          color: "#fff",
          paddingTop: "70px", // offset fixed navbar
        }}
      >
        <div className="container text-center">
          <h1 className="display-4">Omar Abdel Aziz</h1>
          <p className="lead">
            Computer Science Student | Computational Biology Research | Software
            Engineering
          </p>
          <p>
            <a
              href="mailto:oaziz05@cs.washington.edu"
              className="text-white text-decoration-underline"
            >
              oaziz05@cs.washington.edu
            </a>{" "}
            &nbsp;|&nbsp;
            <a
              href="https://www.linkedin.com/in/abdel-aziz-omar"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-decoration-underline"
            >
              LinkedIn
            </a>{" "}
            &nbsp;|&nbsp;
            <a
              href="https://github.com/omar-abdel-aziz"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-decoration-underline"
            >
              GitHub
            </a>
          </p>
        </div>
      </section>

      {/* Education Section */}
      <section id="education" className="py-5">
        <div className="container">
          <h2 className="mb-4">Education</h2>
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <h4 className="card-title">
                University of Washington, Paul G. Allen School of Computer
                Science and Engineering
              </h4>
              <p className="card-text">
                <strong>B.S. in Computer Science</strong> — Seattle, WA
                (September 2023 – June 2026)
              </p>
              <p className="card-text">
                <strong>Courses:</strong> CSE 311 (Discrete Math), CSE 351 (x86
                ASM), CSE 332 (Data Structures &amp; Parallelism), CSE 312
                (Probability), CSE 344 (Databases), CSE 333 (Systems
                Programming), CSE 484 (Computer Security), CSE 331 (Software
                Engineering, Design Patterns &amp; Testing), CSE 421
                (Algorithms)
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Publications Section */}
      <section id="publications" className="py-5 bg-light">
        <div className="container">
          <h2 className="mb-4">Publications / Preprints</h2>
          <div className="list-group">
            <a
              href="https://www.biorxiv.org/content/10.1101/2025.01.22.634135v1"
              target="_blank"
              rel="noopener noreferrer"
              className="list-group-item list-group-item-action flex-column align-items-start"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">
                  Clonal Interference Shapes SARS‑CoV‑2 Antibody Escape
                </h5>
                <small>bioRxiv Preprint, 2025</small>
              </div>
              <p className="mb-1">
                Leveraging deep mutational scanning data, this preprint
                quantifies how SARS‑CoV‑2 escapes 1,603 monoclonal antibodies
                over time. It reveals complex evolutionary dynamics—driven by
                clonal interference and shifting selective pressures—that
                illuminate the virus’s gradual evasion of polyclonal immune
                responses.
              </p>
              <small>[Paper]</small>
            </a>
          </div>
        </div>
      </section>

      {/* Research Experience Section */}
      <section id="research-experience" className="py-5">
        <div className="container">
          <h2 className="mb-4">Research Experience</h2>

          {/* Fred Hutchinson Cancer Research Center */}
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <h4 className="card-title">
                Fred Hutchinson Cancer Research Center — Undergraduate
                Computational Researcher
              </h4>
              <p className="card-text">
                <em>Seattle, WA | June 2023 – Present</em>
              </p>
              <h5>COVID Research</h5>
              <ul>
                <li>
                  Conducted computational research on COVID lineages using
                  Python and scikit-learn, uncovering evolutionary patterns in
                  response to human antibodies and deepening our understanding
                  of COVID mutation pathways.
                </li>
                <li>
                  Preprocessed data and applied UMAP for dimensionality
                  reduction on antibody escape data, enabling the efficient use
                  of k‑means and HDBSCAN clustering to analyze COVID
                  transmission patterns—resulting in improved clustering
                  accuracy and valuable insights into key mutational trends.
                </li>
              </ul>
              <h5>T‑Cell Receptor Research</h5>
              <ul>
                <li>
                  Optimized a neural network model for T‑cell receptor CDR
                  region A‑distance calculations using triplet loss, improving
                  weight distribution and reducing triplet margin loss to
                  enhance immune response prediction.
                </li>
                <li>
                  Leveraged PyTorch and NumPy for efficient model training,
                  leading to faster computations and overall improved
                  performance.
                </li>
              </ul>
            </div>
          </div>

          {/* Institute for Systems Biology */}
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <h4 className="card-title">
                Institute for Systems Biology — Software Engineer, Computational
                Biology Intern
              </h4>
              <p className="card-text">
                <em>Seattle, WA | July 2022 – August 2022</em>
              </p>
              <ul>
                <li>
                  Built a Neo4j graph database mapping cancer genes to drug
                  responses, enabling over 100 researchers across the U.S. to
                  visualize complex relationships between gene expression and
                  cancer drug responses.
                </li>
                <li>
                  Designed and implemented backend services and APIs using
                  Cypher queries and Python, enhancing data access by 15%.
                </li>
              </ul>
            </div>
          </div>

          {/* Link to Publications */}
          <div className="text-center mt-4">
            <a href="#publications" className="btn btn-outline-primary">
              View Research Publications
            </a>
          </div>
        </div>
      </section>

      {/* Professional Experience Section */}
      <section id="professional-experience" className="py-5 bg-light">
        <div className="container">
          <h2 className="mb-4">Professional Experience</h2>

          {/* Quran Connect */}
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <h4 className="card-title">
                Quran Connect — Full Stack Engineer and Founding Member
              </h4>
              <p className="card-text">
                <em>Redmond, WA | September 2020 – July 2022</em>
              </p>
              <ul>
                <li>
                  Developed a cross‑platform iOS/Android app using React Native
                  and JavaScript for real‑time classroom management and
                  communication, utilized by over 10 Islamic schools in the
                  Seattle area.
                </li>
                <li>
                  Deployed backend services with Firebase Functions, integrated
                  Firestore for scalable data management, and implemented
                  Firebase Authentication for secure and seamless user access.
                </li>
                <li>
                  Created robust messaging and notification systems, resulting
                  in a 35% increase in student engagement and a 25% improvement
                  in Arabic comprehension and Quran memorization.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Projects & Outside Experience Section */}
      <section id="projects" className="py-5">
        <div className="container">
          <h2 className="mb-4">Projects &amp; Outside Experience</h2>

          {/* UW CSE 331 - Teaching Assistant */}
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <h4 className="card-title">UW CSE 331 — Teaching Assistant</h4>
              <p className="card-text">
                <em>Seattle, WA | September 2024 – Present</em>
              </p>
              <ul>
                <li>
                  Led weekly section classes for 30 students, providing
                  instruction on React.js, full stack development, software
                  testing, and discrete mathematics proofs.
                </li>
                <li>
                  Conducted weekly office hours, assisting an average of 15
                  students per session with homework and reinforcing course
                  material.
                </li>
                <li>
                  Assisted in curriculum development by enhancing lecture
                  materials and assignments for over 200 students per quarter,
                  resulting in a 15% improvement in assignment completion time.
                </li>
              </ul>
            </div>
          </div>

          {/* Muslim Student Association Website */}
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <h4 className="card-title">
                Muslim Student Association Website — Developer
              </h4>
              <p className="card-text">
                <em>Seattle, WA</em>
              </p>
              <ul>
                <li>
                  Developed a React.js website to centralize event and program
                  communications.
                </li>
                <li>
                  Built an intuitive admin dashboard enabling board members to
                  efficiently update events and manage quick links, thereby
                  improving content accuracy.
                </li>
                <li>
                  Integrated Firebase Authentication for secure admin logins and
                  utilized Firebase Firestore for dynamic content management and
                  storage.
                </li>
              </ul>
            </div>
          </div>

          {/* 333gle Web Server */}
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <h4 className="card-title">
                333gle Web Server — School Project (CSE 333 Systems Programming)
              </h4>
              <p className="card-text">
                <em>Seattle, WA</em>
              </p>
              <ul>
                <li>
                  Designed and implemented data structures (doubly‑linked lists,
                  hash tables, inverted indices) in C/C++ for efficient file
                  parsing, indexing, and search functionality in a custom search
                  engine.
                </li>
                <li>
                  Built a disk‑based search engine capable of storing and
                  retrieving large datasets in architecture‑neutral binary
                  formats, integrating file marshalling and query processing for
                  scalable search operations.
                </li>
                <li>
                  Developed a multithreaded HTTP server in C++ with a
                  thread‑pool architecture to serve static files and handle
                  dynamic search queries.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Skills & Interests Section */}
      <section id="skills" className="py-5 bg-light">
        <div className="container">
          <h2 className="mb-4">Skills &amp; Interests</h2>
          <div className="card shadow-sm">
            <div className="card-body">
              <h5>Programming Languages</h5>
              <p>Java, JavaScript, TypeScript, Python, C, C++</p>
              <h5>Frameworks &amp; Technologies</h5>
              <p>
                React.js, React Native, HTML, CSS, Graph Databases (Neo4j),
                Firebase, Google Cloud Platform, Scikit-learn, NumPy, PyTorch,
                Express.js, Pandas, Cypher Query Language, SQL, SQL++, Flask,
                FastAPI, Swift, Node.js
              </p>
              <h5>Technical Skills</h5>
              <p>
                Data Science, Data Analysis, Full Stack Development,
                Object-Oriented Programming, Backend Development, API
                Development
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-5">
        <div className="container text-center">
          <h2 className="mb-4">Contact</h2>
          <p className="mb-4">
            Feel free to reach out via email or connect on LinkedIn.
          </p>
          <a
            href="mailto:oaziz05@cs.washington.edu"
            className="btn btn-primary me-2"
          >
            Email
          </a>
          <a
            href="https://www.linkedin.com/in/abdel-aziz-omar"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-info me-2"
          >
            LinkedIn
          </a>
          <a
            href="https://github.com/omar-abdel-aziz"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-dark"
          >
            GitHub
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-dark text-light py-3">
        <div className="container text-center">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} Omar Abdel Aziz. All rights
            reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Research;
